import React, { useState } from "react"
import Navbar from "../navbar"
import "./style.scss"
import AdvertisingForm from "./AdvertisingForm"
import MembershipForm from "./MembershipForm"
import GeneralForm from "./GeneralForm"
import Layout from "../layout"
import SEO from "../seo"

const tabs = ["Advertising", "Institutional Membership", "General"]

export default function ConnectPage() {
  const [currentTab, setCurrentTab] = useState(tabs[0])
  const [currentForm, setCurrentForm] = useState(<AdvertisingForm />)

  function changeCurrentTab(tab) {
    setCurrentTab(tab)

    if (tab === tabs[0]) {
      setCurrentForm(<AdvertisingForm />)
    } else if (tab === tabs[1]) {
      setCurrentForm(<MembershipForm />)
    } else if (tab === tabs[2]) {
      setCurrentForm(<GeneralForm />)
    }
  }

  const tabsJSX = tabs.map((tab, i) => (
    <div
      className={`connect__tabs__tab ${`connect__tabs__tab${i + 1} `} ${
        currentTab === tab ? " connect__tabs__tab--selected" : ""
      }`}
      onClick={() => changeCurrentTab(tab)}
      onKeyDown={() => changeCurrentTab(tab)}
    >
      {tab}
    </div>
  ))

  return (
    <Layout>
      <SEO
        title="School LIVE | Reach out for Membership & Advertising"
        description="Reach out to learn more about School LIVE magazine's content, reach and audience, institutional membership, individual subscriptions and advertising in the magazine."
      />
      <div className="connect">
        <Navbar />
        <hr className="hr" />
        <h1 className="heading-primary">Connect</h1>
        <div className="row">
          <div className="col col-12 col-lg-5">
            <div className="connect__tabs">{tabsJSX}</div>
          </div>
          <div className="col col-12 col-lg-7">{currentForm}</div>
        </div>
      </div>
    </Layout>
  )
}
